import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';


import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {LandingComponent} from './landing/landing.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AngularSvgIconModule, SvgIconRegistryService} from 'angular-svg-icon';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxPrintModule} from 'ngx-print';
import {PicturesComponent} from './landing/pictures/pictures.component';
import {LoginBoxComponent} from './landing/login-box/login-box.component';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppToastComponent} from './toast/app-toast/app-toast.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {BaseLayoutComponent} from './base-layout/base-layout.component';
import {InfoBoxButtonComponent} from './base-layout/info-box/info-box-button.component';
import {headerReducers, headerStateName} from './reducers/header.reducer';
import {HeaderEffects} from './reducers/header.effects';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SideBarComponent} from './helpers/side-bar/side-bar.component';
import {sidebarReducers, sidebarStateName} from './reducers/sidebar.reducer';
import {BenutzerInfoReducers, BenutzerInfoStateName} from './reducers/authinfo.reducer';
import {AuthinfoEffects} from './reducers/authinfo.effects';
import {AccountButtonComponent} from './base-layout/account-button/account-button.component';
import {HttpErrorInterceptor} from './api/http-error.interceptor';
import {navigationReducers, navigationStateName} from './reducers/navigation.reducer';
import {CarouselComponent} from './shared/carousel/carousel.component';
import {DifaScienceInfoComponent} from './user-popups/difa-science-info/difa-science-info.component';
import {UserPopupsService} from './user-popups/user-popups.service';
import localeDe from '@angular/common/locales/de';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {CookieWarnungComponent} from './cookie-warnung/cookie-warnung.component';
import {CookieWarnungContentComponent} from './cookie-warnung/cookie-warnung-content/cookie-warnung-content.component';
import {Angulartics2Module} from 'angulartics2';
import {PatientenSelbstEinschreibungModule} from './patienten-selbst-einschreibung/patienten-selbst-einschreibung.module';
import { AgbDatenschutzAktualisierungComponent } from './user-popups/agb-datenschutz-aktualisierung/agb-datenschutz-aktualisierung.component';
import {
  extradataInstanceReducers,
  extradataInstancesStateName,
  krankenkassenDataReducers,
  krankenkassenDataStateName,
} from './reducers/userdata.reducer';
import {ExtradataInstanceEffects, KrankenkassenDataEffects} from './reducers/userdata.effects';
import { DifaFachaerztetag2021Component } from './user-popups/difa-fachaerztetag2021/difa-fachaerztetag2021.component';
import { LexOnlineAktivierungPremiumInfoPopupComponent } from './user-popups/lex-online-aktivierung-premium-info-popup/lex-online-aktivierung-premium-info-popup.component';
import {
  TelefonnummerWirdPflichtfeldPopupComponent
} from './user-popups/telefonnummer-wird-pflichtfeld/telefonnummer-wird-pflichtfeld-popup.component';
import {StammdatenModule} from './stammdaten/stammdaten.module';
import {BreadcrumbsComponent} from './base-layout/breadcrumbs/breadcrumbs.component';
import { HybridDRGAbrechnungFlyerPopupComponent } from './user-popups/hybrid-drgabrechnung-flyer-popup/hybrid-drgabrechnung-flyer-popup.component';
import { NovitasBKKHKSInformationPopupComponent } from './user-popups/novitas-bkkhksinformation-popup/novitas-bkkhksinformation-popup.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    PageNotFoundComponent,
    PicturesComponent,
    LoginBoxComponent,
    AppToastComponent,
    BaseLayoutComponent,
    InfoBoxButtonComponent,
    SideBarComponent,
    AccountButtonComponent,
    CarouselComponent,
    DifaScienceInfoComponent,
    CookieWarnungComponent,
    CookieWarnungContentComponent,
    AgbDatenschutzAktualisierungComponent,
    DifaFachaerztetag2021Component,
    LexOnlineAktivierungPremiumInfoPopupComponent,
    TelefonnummerWirdPflichtfeldPopupComponent,
    BreadcrumbsComponent,
    HybridDRGAbrechnungFlyerPopupComponent,
    NovitasBKKHKSInformationPopupComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AngularSvgIconModule.forRoot(),
    NgxPrintModule,
    /* Store */
    StoreModule.forRoot({}),
    StoreModule.forFeature(headerStateName, headerReducers),
    StoreModule.forFeature(sidebarStateName, sidebarReducers),
    StoreModule.forFeature(BenutzerInfoStateName, BenutzerInfoReducers),
    StoreModule.forFeature(navigationStateName, navigationReducers),
    StoreModule.forFeature(extradataInstancesStateName, extradataInstanceReducers),
    StoreModule.forFeature(krankenkassenDataStateName, krankenkassenDataReducers),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
      HeaderEffects,
      AuthinfoEffects,
      ExtradataInstanceEffects,
      KrankenkassenDataEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    // tslint:disable-next-line:whitespace
    // RecaptchaV3Module,
    RecaptchaModule,
    SharedModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    Angulartics2Module.forRoot(),
    PatientenSelbstEinschreibungModule,
    StammdatenModule,
    NgOptimizedImage,

  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de' // 'de-DE' for Germany, 'fr-FR' for France ...
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    UserPopupsService,
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcmelMUAAAAAHbtcLEnMtIfxTI3hDHR3QWULEGL' },
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private iconReg: SvgIconRegistryService) {
    iconReg.loadSvg('assets/icons/svg/info.svg', 'info').subscribe();
    iconReg.loadSvg('assets/icons/svg/rotate-smartphone.svg', 'rotateSmartphone').subscribe();
  }
}
