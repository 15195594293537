import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BenutzermanagementAPIService} from '../api/benutzermanagement/benutzermanagement-api.service';
import {Store} from '@ngrx/store';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {getBenutzerInfoFeatureState} from '../reducers/authinfo.reducer';
import {Subscription} from 'rxjs';
import {DifaScienceInfoComponent} from './difa-science-info/difa-science-info.component';
import {
  AgbDatenschutzAktualisierungComponent
} from './agb-datenschutz-aktualisierung/agb-datenschutz-aktualisierung.component';
import {getExtradataInstanceFeatureState} from '../reducers/userdata.reducer';
import {environment} from '../../environments/environment';
import {LoadingState} from '../shared/reducer.includes';
import {DifaFachaerztetag2021Component} from './difa-fachaerztetag2021/difa-fachaerztetag2021.component';
import {FooterModalsService} from '../shared/footer-modals.service';
import {
  LexOnlineAktivierungPremiumInfoPopupComponent
} from './lex-online-aktivierung-premium-info-popup/lex-online-aktivierung-premium-info-popup.component';
import {
  TelefonnummerWirdPflichtfeldPopupComponent
} from './telefonnummer-wird-pflichtfeld/telefonnummer-wird-pflichtfeld-popup.component';
import {
  HybridDRGAbrechnungFlyerPopupComponent
} from './hybrid-drgabrechnung-flyer-popup/hybrid-drgabrechnung-flyer-popup.component';
import {NovitasBKKHKSInformationPopupComponent} from './novitas-bkkhksinformation-popup/novitas-bkkhksinformation-popup.component';

@Injectable({
  providedIn: 'root'
})
export class UserPopupsService implements OnDestroy {
  private popups: string[] = [];
  private benutzerInfoSub$: Subscription;
  private benutzerEDISub$: Subscription;
  private knownPopups: {
    [key: string]: {
      component: any,
      size: 'm' | 'xl' | 'lg',
      backdrop?: 'static' | boolean
    }
  } = {
    'DIFA Science Info Popup': {
      component: DifaScienceInfoComponent,
      size: 'xl',
    },
    'AGB & Datenschutz Aktualisierung (v2)': {
      component: AgbDatenschutzAktualisierungComponent,
      size: 'xl',
    },
    'Lex Online Aktivierung Premium Info': {
      component: LexOnlineAktivierungPremiumInfoPopupComponent,
      size: 'lg',
    },
    'Telefonnummer wird Pflichtfeld': {
      component: TelefonnummerWirdPflichtfeldPopupComponent,
      size: 'lg',
    },
    'Hybrid DRG Abrechnung Flyer': {
      component: HybridDRGAbrechnungFlyerPopupComponent,
      size: 'xl',
      backdrop: true,
    },
    'Novitas BKK HKS Information': {
      component: NovitasBKKHKSInformationPopupComponent,
      size: 'lg',
    },
  };
  private currentModal: NgbModalRef;
  private shownPopups: Set<string> = new Set<string>();

  constructor(private benutzerApiClient: BenutzermanagementAPIService,
              private store: Store,
              private modalService: NgbModal, private fms: FooterModalsService) {

    this.knownPopups[DifaFachaerztetag2021Component.ID] = {
      component: DifaFachaerztetag2021Component,
      size: 'm',
    };

    this.benutzerInfoSub$ = this.store.select(getBenutzerInfoFeatureState).subscribe(value => {
      if (value.auth.isAuthenticated) {
        this.benutzerApiClient.getPopups().subscribe(popups => {
          for (const popup of popups) {
            this.addPopup(popup);
          }
        });
      }
    });
    this.benutzerEDISub$ = this.store.select(getExtradataInstanceFeatureState).subscribe(value => {
      if (value?.ExtradataInstances.loadingState !== LoadingState.Loaded) {
        return;
      }
      let showAgbPopup = false;
      let showTelefonnummerPopup = true;

      for (const d of value.ExtradataInstances.alleExtradataInstances) {
        const key = `${d.extradata.namespace}/${d.extradata.name}`;
        if (key === 'Sanakey/AGB akzeptiert (Version)' && d.value < environment.version_agb) {
          showAgbPopup = true;
        }
        if (key === 'Sanakey/Datenschutzbestimmungen akzeptiert (Version)' && d.value < environment.version_datenschutz) {
          showAgbPopup = true;
        }
        if (key === 'Sanakey/Telefonnummer' && d.value) {
          showTelefonnummerPopup = false;
        }
      }

      if (showAgbPopup) {
        this.addPopup('AGB & Datenschutz Aktualisierung (v2)');
      }
    });
  }

  ngOnDestroy(): void {
    this.benutzerInfoSub$?.unsubscribe();
    this.benutzerEDISub$?.unsubscribe();
  }

  private addPopup(popup: string) {
    if (this.popups.indexOf(popup) >= 0) {
      return;
    }
    this.popups.push(popup);
    this.showNextPopup();
  }

  private showNextPopup() {
    this.fms.closeProcessError();
    if (this.currentModal) {
      return;
    }

    if (this.popups.length) {
      const popupName = this.popups.shift();
      const popupComponent = this.knownPopups[popupName];
      if (!popupComponent) {
        console.warn('Popup unbekannt: ' + popupName);
        setTimeout(this.showNextPopup.bind(this));
        return;
      }
      if (this.shownPopups.has(popupName)) {
        setTimeout(this.showNextPopup.bind(this));
        return;
      }

      this.shownPopups.add(popupName);

      let backdrop: 'static' | boolean = 'static';
      if (popupComponent.backdrop === false || popupComponent.backdrop === true) {
        backdrop = popupComponent.backdrop;
      }

      this.currentModal = this.modalService.open(popupComponent.component, {
        backdrop,
        size: popupComponent.size,
        keyboard: false,
      });
      this.currentModal.result.finally(this.resetAndShowNextPopup.bind(this));
    }
  }

  private resetAndShowNextPopup() {
    this.currentModal = null;
    this.showNextPopup();
  }
}
